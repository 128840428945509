<template>
	<div class="container">

		<div class="loginTop">
			<!-- <img :src="loginImg" class="loginBg">
            <img :src="logoImg" class="logoImg"> -->
			<div style="text-align: left;font-size: 26px;padding: 20px;">账号登录</div>
		</div>
		<van-cell-group>
			<van-field v-model="username" label="用户名" placeholder="请输入用户名"
				:rules="[{ required: true, message: '请输入用户名' }]" />
			<van-field v-model="password" type="password" label="密码" placeholder="请输入密码"
				:rules="[{ required: true, message: '请输入密码' }]" />
		</van-cell-group>

		<div>
			<van-button type="warning" color="#ffad1a" block @click="login">登 录</van-button>
			<!--  <van-button type="danger" color="#ffad1a" plain block @click="smsLogin" style="margin-top: 5px;">手机登录</van-button> -->
		</div>
		<div style="display: flex;justify-content: space-between; padding: 2px 12px;">
			<a href="javascript:;" class="forget-text" style="color:black;" @click="smsLogin">手机短信登录 ></a>
			<!-- <a href="javascript:;" class="forget-text" @click="forgetPwd">忘记密码?</a>    -->
		</div>

	</div>
</template>
<script type="text/javascript">
	import request from '@/utils/request';
	import {
		Toast
	} from 'vant';
	export default {
		name: "Login",
		data() {
			return {

				loginImg: require("@/assets/images/loginBg.png"),
				logoImg: require("@/assets/images/logo.png"),
				username: "",
				password: ""
			}
		},
		created() {
			// console.log(this.$router)
			this.hideShare();
		},
		methods: {
			forgetPwd() {
				this.$router.push("/forgetPwd")
			},
			login() {
				this.doPost('/api/login/pwdLogin', {
					username: this.username,
					password: this.password
				},response => {
					if (response.code == 0) {
						this.onLoginSuccess(response.data);
						//回调地址                   
						if(!response.data.wxAuth&&this.isWeiXinBrower()){
                        
						  let wxUrl="https://open.weixin.qq.com/connect/oauth2/authorize?appid="+response.data.appId;

							wxUrl+= "&redirect_uri="+encodeURIComponent(location.origin+"/mine");

							wxUrl+="&response_type=code&scope=snsapi_userinfo#wechat_redirect";
							// alert(wxUrl)
							location.href=wxUrl; 
							return;
						}

					} else {
						//登录失败
						Toast.fail(response.msg);
					}
				});
			},
			smsLogin() {
				this.$router.push("/smsLogin")
			}
		}
	}
</script>
<style type="text/css" scoped="">
	body {
		background: #fff;
	}

	.container {
		height: 100%;
		width: 100%;
		position: fixed;
		left: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		background: #fff;
		padding: 10% 5%;
		box-sizing: border-box;
	}

	.forget-text {
		font-size: 14px;
		float: right;
		padding: 5px 0px;
		color: red;
	}

	.loginTop {
		position: relative;
		margin-bottom: 10%;
	}

	.loginBg {
		width: 100%;
	}

	.logoImg {
		position: absolute;
		margin: 0 auto;
		top: 50%;
		left: 50%;
		width: 25%;
		transform: translate(-50%, -50%);
	}

	.van-button {
		width: 95%;
		margin: 15% 2% 5% 2%;
		border-radius: 30px;
		background: -webkit-linear-gradient(left, #ff6034, #ee0a24) !important;
		background: linear-gradient(to right, #ff6034, #ee0a24) !important;
		border: 0px;
		border: 0px;
	}
</style>